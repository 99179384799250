<template>
  <div>
    <b-navbar
      fixed="top"
      toggleable="lg"
      type="light"
      variant="light"
    >
      <b-navbar-brand :to="{ name: 'home' }">
        Extranet | xConta Invoicing Bridge
      </b-navbar-brand>

      <b-navbar-toggle target="nav_collapse" />

      <b-collapse
        id="nav_collapse"
        is-nav
      >
        <b-navbar-nav v-if="userId">
          <b-nav-item :to="{ name: 'invoices' }">
            Facturi
          </b-nav-item>
          <b-nav-item :to="{ name: 'clients' }">
            Clienți
          </b-nav-item>
          <b-nav-item :to="{ name: 'objectiveMappings' }">
            Mapări obiective
          </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav
          v-if="userId"
          class="ml-auto"
        >
          <b-nav-item-dropdown right>
            <template #button-content>
              <span>{{ fullName }}</span> <b-avatar
                size="24"
                rounded
                class="ml-1"
              >
                <font-awesome-icon icon="fa-solid fa-user" />
              </b-avatar>
            </template>
            <b-dropdown-item
              v-if="role === 'Administrator'"
              :to="{ name: 'usersAdmin' }"
            >
              Administrare utilizatori
            </b-dropdown-item>
            <b-dropdown-item
              v-if="role === 'Administrator'"
              @click="clearExtranetTokensClicked"
            >
              Golește auth tokens
            </b-dropdown-item>
            <b-dropdown-item
              v-if="role === 'Administrator'"
              @click="restartAppClicked"
            >
              Repornește aplicația
            </b-dropdown-item>
            <b-dropdown-item
              @click="changePasswordClicked"
            >
              Schimbă parola
            </b-dropdown-item>
            <b-dropdown-item
              @click="logoutClicked"
            >
              Logout
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <b-modal
      id="changePasswordModal"
      ref="changePasswordModal"
      @ok="onChangePasswordOk"
    >
      <b-form-group
        label="Parola curentă"
      >
        <b-form-input
          v-model="changePassword.currentPassword"
          type="password"
        />
      </b-form-group>
      <b-form-group
        label="Parola nouă"
      >
        <b-form-input
          v-model="changePassword.newPassword"
          type="password"
        />
      </b-form-group>
      <b-form-group
        label="Parola nouă (repetată)"
      >
        <b-form-input
          v-model="changePassword.repeatNewPassword"
          type="password"
        />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions, mapState } from 'pinia';

import useSessionStore from '@/store/session';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Navbar',

  data() {
    return {
      changePassword: {
        currentPassword: null,
        newPassword: null,
        repeatNewPassword: null,
      },
    };
  },

  computed: {
    ...mapState(useSessionStore, ['userId', 'username', 'role', 'fullName']),
  },

  methods: {
    ...mapActions(useSessionStore, ['performLogout']),

    async changePasswordClicked() {
      this.changePassword = {
        currentPassword: null,
        newPassword: null,
        repeatNewPassword: null,
      };

      this.$bvModal.show('changePasswordModal');
    },

    async clearExtranetTokensClicked() {
      if (await this.$bvModal.msgBoxConfirm('Confirmi golirea token-urilor de autentificare in Extranet?') !== true) {
        return;
      }

      await axios.delete('AppAdmin/extranet-tokens');
      await this.$bvModal.msgBoxOk('Token-urile de autentificare în Extranet au fost golite. Se vor genera token-uri noi la următoarea sincronizare.');
    },

    async restartAppClicked() {
      if (await this.$bvModal.msgBoxConfirm('Confirmi repornirea aplicației?') !== true) {
        return;
      }

      await axios.post('AppAdmin/stop');
      await this.$bvModal.msgBoxOk('Aplicația a fost repornită, pagina se va reîncărca în curând...', { hideFooter: true, okDisabled: true });

      setTimeout(() => window.location.reload(), 5000);
    },

    async logoutClicked() {
      await this.performLogout();

      this.$router.push({ name: 'login' });
    },

    async onChangePasswordOk() {
      try {
        await axios.post('UserProfile/change-password', this.changePassword);

        this.$root.$bvToast.toast('Operațiune reușită', {
          variant: 'success',
          toaster: 'b-toaster-top-right',
        });
      } catch {
        this.$root.$bvToast.toast('Eroare la efectuarea operațiunii', {
          variant: 'danger',
          toaster: 'b-toaster-top-right',
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
nav {
  margin-bottom: 30px;

  background-color: rgba(248, 249, 250, 0.965) !important;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
