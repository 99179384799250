<template>
  <div id="app">
    <Navbar />
    <transition
      name="fade-transform"
      mode="out-in"
    >
      <router-view />
    </transition>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';

export default {
  name: 'App',
  components: {
    Navbar,
  },
};
</script>

<style lang="scss">
body {
  // background: linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), url("/img/backgrounds/email-pattern.png") repeat scroll 0% 0%;
}

#app {
  margin-top: 80px;
  margin-bottom: 50px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.component-fade-leave-active {
  transition: opacity .15s ease;
}
.component-fade-enter-active {
  transition: opacity .25s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
