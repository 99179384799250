import Vue from 'vue';
import VueRouter from 'vue-router';

import pinia from '@/store/instance';
import useSessionStore from '@/store/session';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/invoices',
  },
  {
    path: '/invoices',
    name: 'invoices',
    meta: { title: 'Facturi' },
    component: () => import(/* webpackChunkName: "about" */ '../views/InvoicesView.vue'),
  },
  {
    path: '/clients',
    name: 'clients',
    meta: { title: 'Clienți' },
    component: () => import(/* webpackChunkName: "about" */ '../views/ClientsView.vue'),
  },
  {
    path: '/objective-mappings',
    name: 'objectiveMappings',
    meta: { title: 'Mapări obiective' },
    component: () => import(/* webpackChunkName: "about" */ '../views/ObjectiveMappingsView.vue'),
  },
  {
    path: '/users-admin',
    name: 'usersAdmin',
    meta: { title: 'Administrare utilizatori' },
    component: () => import(/* webpackChunkName: "about" */ '../views/UsersAdminView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    meta: { allowAnonymous: true, title: 'Autentificare' },
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
  },
  {
    path: '/404',
    name: 'notFound',
    meta: { allowAnonymous: true, title: 'Pagină inexistentă' },
    component: () => import(/* webpackChunkName: "about" */ '../views/NotFound.vue'),
  },
  {
    path: '/*',
    redirect: { name: 'notFound' },
  },
];

const sessionStore = useSessionStore(pinia);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, _, next) => {
  if (to.meta.allowAnonymous) {
    return next();
  }

  await sessionStore.refreshSession();

  const isLoggedIn = !!sessionStore.userId;
  if (isLoggedIn) {
    return next();
  }

  return next({ name: 'login' });
});

router.afterEach((to) => {
  const rootTitle = 'Extranet | xConta Invoicing Bridge';
  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} — ${rootTitle}`;
  }
});

export default router;
