import './custom.scss';

import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import { BootstrapVue } from 'bootstrap-vue';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import Multiselect from 'vue-multiselect';

import numeral from 'numeral';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUser, faEllipsis, faExclamationTriangle, faSquareCheck, faTriangleExclamation, faGears, faBoxArchive, faBoxOpen, faLinkSlash, faLink, faCircleInfo, faFileInvoice, faUpload, faRotate, faRotateRight, faSwatchbook, faEraser,
} from '@fortawesome/free-solid-svg-icons';

import {
  faFilePdf,
} from '@fortawesome/free-regular-svg-icons';

import pinia from './store/instance';

// eslint-disable-next-line import/first
import App from './App.vue';
// eslint-disable-next-line import/first
import router from './router';

Vue.use(VueCompositionAPI);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Multiselect', Multiselect);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);

/* add icons to the library */
library.add(faUser);
library.add(faEllipsis);
library.add(faExclamationTriangle);
library.add(faSquareCheck);
library.add(faTriangleExclamation);
library.add(faGears);
library.add(faBoxArchive);
library.add(faBoxOpen);
library.add(faFilePdf);
library.add(faLinkSlash);
library.add(faLink);
library.add(faCircleInfo);
library.add(faFileInvoice);
library.add(faUpload);
library.add(faRotate);
library.add(faRotateRight);
library.add(faSwatchbook);
library.add(faEraser);

numeral.register('locale', 'ro', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'mii',
    million: 'mln',
    billion: 'mld',
    trillion: 'tld',
  },
  ordinal(number) {
    return number === 1 ? 'ul' : 'lea';
  },
  currency: {
    symbol: 'RON',
  },
});
numeral.locale('ro');

/* add font awesome icon component */
Vue.component('FontAwesomeIcon', FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  pinia,
  render: (h) => h(App),
}).$mount('#app');
