import { defineStore } from 'pinia';
import axios from 'axios';
import './instance';

const useSessionStore = defineStore('session', {
  state: () => ({
    userId: null,
    username: null,
    email: null,
    fullName: null,
    role: null,
    defaultInvoicingOperatorId: null,
  }),
  actions: {
    async refreshSession() {
      const statusRes = await axios.get('Session/status');

      this.userId = statusRes.data.userId || null;
      this.username = statusRes.data.username || null;
      this.email = statusRes.data.email || null;
      this.fullName = statusRes.data.fullName || null;
      this.role = statusRes.data.role || null;
      this.defaultInvoicingOperatorId = statusRes.data.defaultInvoicingOperatorId || null;

      return this.userId !== null;
    },

    async postLogin({
      username,
      password,
      recaptcha,
      rememberMe,
    }) {
      try {
        const statusRes = await axios.post('Session/login', {
          username,
          password,
          recaptcha,
          rememberMe,
        });

        if (statusRes.status !== 200) {
          return false;
        }

        this.userId = statusRes.data.userId;
        this.username = statusRes.data.username || null;
        this.email = statusRes.data.email || null;
        this.fullName = statusRes.data.fullName || null;
        this.role = statusRes.data.role || null;
        this.defaultInvoicingOperatorId = statusRes.data.defaultInvoicingOperatorId || null;

        return this.userId !== null;
      } catch (err) {
        if (err.response && err.response.status === 401) {
          return false;
        }

        throw err;
      }
    },

    async performLogout() {
      await axios.get('Session/logout');

      this.userId = null;
      this.username = null;
      this.email = null;
      this.fullName = null;
      this.role = null;
      this.defaultInvoicingOperatorId = null;
    },
  },
});

export default useSessionStore;
